import cn from 'classnames';
import { forwardRef, ReactNode } from 'react';
import styles from './ContentManagerSection.module.scss';

export type ContentManagerSectionProps = {
  className?: string;
  contentClassName?: string;
  children: ReactNode;
  after?: ReactNode;
  before?: ReactNode;
  id?: string;
};

export const ContentManagerSection = forwardRef<HTMLElement, ContentManagerSectionProps>(
  ({ className = '', contentClassName, children, after, before, id }, ref) => {
    return (
      <section className={cn(styles.root, className)} ref={ref} id={id}>
        {before}
        <div className={cn(styles.content, contentClassName)}>{children}</div>
        {after}
      </section>
    );
  },
);

export default ContentManagerSection;
